import "./countdown_timer.scss";

function initCountdownTimer() {
  const countdownTimers = document.querySelectorAll(".countdown-timer");

  if (countdownTimers) {
    for (let countdownTimer of countdownTimers) {
      const startsAt = new Date(countdownTimer.getAttribute("data-starts"));
      const endsAt = new Date(countdownTimer.getAttribute("data-ends"));
      const countdownUntilStarts = countdownTimer.querySelector(".countdown-until-starts");
      const countdownUntilEnd = countdownTimer.querySelector(".countdown-until-end");
      const countdownEnded = countdownTimer.querySelector(".countdown-ended");
      const countdown = countdownTimer.querySelector(".countdown");

      setInterval(function() {
        let currTime = new Date();

        if (currTime <= endsAt) {
          let distance = 0;

          if (currTime < startsAt) {
            countdownUntilStarts.classList.add("visible");
            countdownUntilEnd.classList.remove("visible");
            distance = new Date(startsAt - currTime);
          } 
          else {
            countdownUntilStarts.classList.remove("visible");
            countdownUntilEnd.classList.add("visible");
            distance = new Date(endsAt - currTime);
          }

          let seconds = distance.getTime() / 1000;

          if (currTime >= startsAt && seconds < 10*60) {
            countdown.classList.add("last-minutes");
          } 
          else {
            countdown.classList.remove("last-minutes");
          }

          countdown.innerText = seconds > 3600 ? distance.toISOString().substr(11, 8) : distance.toISOString().substr(14, 5);
        }
        else {
          countdownUntilStarts.classList.remove("visible");
          countdownUntilEnd.classList.remove("visible");
          countdown.style.display = "none";
          countdownEnded.classList.add("visible");
        }
      }, 1000);
    }
  }
}

document.addEventListener("turbolinks:load", function() {
  initCountdownTimer();
});

document.addEventListener("DOMContentLoaded", function() {
  initCountdownTimer();
});
