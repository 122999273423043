// Stylesheets
// import "@fortawesome/fontawesome-free/js/all";
// import "@fortawesome/fontawesome-free/css/all";
import "../../styles/web/normalize.css";
import "../../styles/web/global.scss";

function throttle(fn, wait) {
  let time = Date.now();

  return function() {
    if ((time + wait - Date.now()) < 0) {
      fn();
      time = Date.now();
    }
  }
}

function parallax() {
  const parallax = document.querySelector(".parallax");
  if (!parallax) return;

  parallax.style.transform = `translateY(${window.pageYOffset * .6}px)`;
}

/*
  Scroll suave a un anchor.
*/
function softAnchorsScroll(e, respond = null) {
  e.preventDefault();

  const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

  let targetID, targetAnchor;

  targetID = (respond) ? respond.getAttribute("href") : this.getAttribute("href");

  if (targetID && targetID != "#") {
    targetAnchor = document.querySelector(targetID);
  }

  if (targetAnchor) {
    const originalTop = distanceToTop(targetAnchor);

    window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });

    const checkIfDone = setInterval(function() {
      const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
      if (distanceToTop(targetAnchor) === 0 || atBottom) {
        targetAnchor.tabIndex = "-1";
        targetAnchor.focus();
        window.history.pushState("", "", targetID);
        clearInterval(checkIfDone);
      }
    }, 100);
  }
}

document.addEventListener("turbolinks:load", function() {
  // bug fix: turbolinks y anchors
  // document.addEventListener('turbolinks:click', function(event) {
  //   if (event.target.getAttribute('href').charAt(0) === '#') {
  //     return event.preventDefault();
  //   }
  // });

  // bug fix: botón atrás en Firefox
  window.addEventListener("unload", event => {
    window.removeEventListener("unload", this);
  });

  // scroll suave al clicar en un anchor
  const links = document.getElementsByTagName("a");

  for (let i = 0; i < links.length; i++) {
    const link = links[i];

    if (
          (link.href && link.href.indexOf("#") != -1)
          &&
          (link.pathname == location.pathname || ('/' + link.pathname == location.pathname && link.search == location.search))
        )
    {
      link.onclick = softAnchorsScroll;
    }
  }
  // </>

  // devuelve el scroll al inicio al cambiar de página o al recargarla
  Turbolinks.BrowserAdapter.prototype.reload = function () {
    window.scrollTo(0, 0);
    window.location.reload();
  }

  // parallax
  if (document.querySelector(".parallax")) {
    window.addEventListener('scroll', throttle(parallax, 14));
    parallax();
  }
});
