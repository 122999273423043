import "./image_input.scss";

document.addEventListener("change", function(event) {
  if (event.target.classList.contains("image-input-file")) {
    const wrapper = event.target.parentNode.parentNode;

    if (event.target.classList.contains("file")) {
      try {
        const reader = new FileReader();
        const fileToRead = event.target.files[0];

        reader.onload = function() {
          const preview = wrapper.querySelector(".preview");
          wrapper.setAttribute("data-original", preview.style.backgroundImage);
          preview.style.backgroundImage = `url("${reader.result}")`;
        };

        reader.readAsDataURL(fileToRead);
      }
      catch (error) {
        wrapper.querySelector(".preview").style.backgroundImage = `url("${wrapper.getAttribute("data-original")}")`;
      }
    }
  }
});
