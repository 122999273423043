// JS
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
window.Swiper = Swiper;

// Stylesheets
import "../../styles/web/home.scss";

document.addEventListener("turbolinks:load", event => {
  if (document.querySelector("body").getAttribute("id") == "staticHome") {
    new Swiper("#popularCoursesCarrousel", {
      slidesPerView: 1.25,
      loop: false,
      spaceBetween: 20,
      speed: 800,
      centeredSlides: false,
      preventInteractionOnTransition: true,
      threshold: 0.05,
      breakpoints: {
        1150: {
          slidesPerView: 4,
          resistance: true,
          resistanceRatio: 0,
        },
        1025: {
          slidesPerView: 3,
          resistance: true,
          resistanceRatio: 0,
        },
        991: {
          slidesPerView: 2.5,
        },
        675: {
          slidesPerView: 2.25,
        },
        575: {
          slidesPerView: 1.75,
        },
        475: {
          slidesPerView: 1.5,
        },
      },
    });

    new Swiper("#categoriesCarrousel", {
      slidesPerView: 1.25,
      loop: false,
      spaceBetween: 20,
      speed: 800,
      centeredSlides: false,
      preventInteractionOnTransition: true,
      threshold: 0.05,
      breakpoints: {
        1025: {
          slidesPerView: 3,
          resistance: true,
          resistanceRatio: 0,
        },
        991: {
          slidesPerView: 2.5,
        },
        675: {
          slidesPerView: 2.25,
        },
        575: {
          slidesPerView: 1.75,

        },
        475: {
          slidesPerView: 1.5,

        },
      },
    });

    new Swiper("#teachersCarrousel", {
      slidesPerView: 1,
      loop: false,
      spaceBetween: 20,
      speed: 800,
      centeredSlides: false,
      preventInteractionOnTransition: true,
      threshold: 0.05,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        676: {
          slidesPerView: 1.5,
        },
        992: {
          slidesPerView: 2,
        },
      }
    });
  }
});
