// Stylesheets
import "../../styles/web/users.scss";

document.addEventListener("turbolinks:load", event => {
  var bodyId = document.querySelector("body").getAttribute("id");
  if ( bodyId == "usersAgenda") {
    new Swiper("#todayClassesCarrousel", {
      slidesPerView: 1.25,
      loop: false,
      spaceBetween: 20,
      speed: 800,
      centeredSlides: false,
      preventInteractionOnTransition: true,
      threshold: 0.05,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: "fraction",
      },
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
      breakpoints: {
        1150: {
          slidesPerView: 4,
          resistance: true,
          resistanceRatio: 0,
        },
        1025: {
          slidesPerView: 3,
          resistance: true,
          resistanceRatio: 0,
        },
        991: {
          slidesPerView: 2.5,
        },
        675: {
          slidesPerView: 2.25,
        },
        575: {
          slidesPerView: 1.75,

        },
        475: {
          slidesPerView: 1.5,

        },
      },
    });
  }

  // user register
  if (bodyId.startsWith('registrations')) {
    let tzinput = document.querySelector('#tzinput');
    if (tzinput) {
      tzinput.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }
  // edit profile
  if (bodyId == 'usersEdit' || bodyId == 'usersUpdate') {
    let tzinput = document.querySelector('select#user_timezone');
    if (!tzinput.value) {
      tzinput.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }

  // toggle passwd visibility
  let passwd_input = document.querySelector('input.passwd-show');
  if (passwd_input) {
    passwd_input.nextElementSibling.addEventListener('click', event => {
      if (event.target.classList.contains('fa-eye-slash')) {
        event.target.classList.add('fa-eye');
        event.target.classList.remove('fa-eye-slash');
        passwd_input.setAttribute('type', 'password');
      } else {
        event.target.classList.add('fa-eye-slash');
        event.target.classList.remove('fa-eye');
        passwd_input.setAttribute('type', 'text');
      }

    })
  }

});
