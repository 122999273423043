// Stylesheets
import "../../styles/web/categories.scss";

document.addEventListener("turbolinks:load", event => {
  if (document.querySelector("body").getAttribute("id") == "categoriesIndex") {
    new Swiper(".swiper-container", {
      slidesPerView: 1.25,
      loop: false,
      spaceBetween: 20,
      speed: 800,
      centeredSlides: false,
      preventInteractionOnTransition: true,
      threshold: 0.05,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: "fraction",
      },
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
      breakpoints: {
        1150: {
          slidesPerView: 4,
          resistance: true,
          resistanceRatio: 0,
        },
        1025: {
          slidesPerView: 3,
          resistance: true,
          resistanceRatio: 0,
        },
        991: {
          slidesPerView: 2.5,
        },
        675: {
          slidesPerView: 2.25,
        },
        575: {
          slidesPerView: 1.75,
        },
        475: {
          slidesPerView: 1.5,
        },
      },
    });
  }
});
