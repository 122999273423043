import "./gallery.scss";
import "glightbox/dist/css/glightbox.min.css";
import GLightbox from 'glightbox';

document.addEventListener("turbolinks:load", function() {
  const gallery = document.querySelector(".lightbox");

  if (gallery) {
    const lightbox = GLightbox({
      selector: '.lightbox'
    });
  }

  const custommodal = document.getElementById('modal');
  if (custommodal) {
    custommodal.addEventListener('opened', (e) => {
      const lightbox = GLightbox({
        selector: '#modal .lightbox'
      });
    });
  }
});
