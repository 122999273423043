import "./filters.scss";

document.addEventListener("turbolinks:load", function() {
  const filters = document.querySelector(".filters");

  if (filters) {
    let filtersToggle = filters.querySelector(".filters--header");
    let filtersBody   = filters.querySelector(".filters--body");

    filtersToggle.addEventListener("click", function() {
      if (!filters.classList.contains("open")) {
        let height = `${filtersToggle.offsetHeight+filtersBody.offsetHeight}px`;
        filters.style.height = height;
      }
      filters.classList.toggle("open");
    });
  }
});
