import "./custom_modal.scss";

const openEvent = new CustomEvent('opened');
document.addEventListener("turbolinks:load", function() {
  // Improve
  let modal = $('#modal')
  $('body').on('click', 'a.remote-popup', (e) => {
    e.preventDefault();
    modal.load($(e.currentTarget).attr('href'), () => {
      modal.addClass('visible');
      modal[0].dispatchEvent(openEvent);
    });
  });
  modal.on('click', (e) => {
    if ($(e.target).attr('id') == 'modal') {
      modal.removeClass('visible');
      modal.html('');
    }
  });
});

document.addEventListener("DOMContentLoaded", function() {
});
