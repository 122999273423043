// Stylesheets
import "../../styles/web/purchases.scss";

let form = null;
function purchase_normal() {
  // alert('normal');
  console.log(this, form);
}

const purchased_named = function() {
  // alert('named');
  console.log(this, form);
}

const purchased_arrow = () => {
  // alert('arrow');
  console.log(this, form);
}

const loaded = (event) => {
  console.log('Purchases loaded!!');
  form = document.getElementById('payment-form');
  purchase_normal();
  purchased_named();
  purchased_arrow();
}

document.addEventListener("xxxturbolinks:load", loaded);

document.addEventListener("xxturbolinks:load", event => {
  var form = document.getElementById('payment-form');
  purchase_normal();
  purchased_named();
  purchased_arrow();
  if (form) {
    console.log('init on app/javascript/scripts/web/purchases.js')
  }
});
