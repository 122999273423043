// JS
import $ from "jquery";
import "../scripts/select2/select2";
import "../scripts/select2/select2.min.css"

function loadCities(country_id, url, city_id) {
  let citySelect = $("#citySelect");

  citySelect.val(null).trigger("change");
  citySelect.html("");

  $.ajax({
    type: "POST",
    url: url,
    dataType: "html",
    data: { country_id: country_id },
  }).done(function(data) {
    if (data.length) {
      let resultJSON = JSON.parse(data);

      // don't want to preselect the first row unless autocomplete #1910
      let option = new Option('--', '', false, false);
      citySelect.append(option);

      $.each(resultJSON, function(i, city) {
        let option = new Option(city.name, city.id, false, city.id == city_id);
        citySelect.append(option);
      });

      citySelect.trigger({
        type: 'select2:select',
        params: {
          data: data
        }
      });
    }
  });
}

window.loadCountryCitySelect2 = function() {
  let countrySelect = $("#countrySelect");
  let url = countrySelect.data("url");

  if (countrySelect.length) {
    let Utils = $.fn.select2.amd.require('select2/utils');
    let Dropdown = $.fn.select2.amd.require('select2/dropdown');
    let DropdownSearch = $.fn.select2.amd.require('select2/dropdown/search');
    let CloseOnSelect = $.fn.select2.amd.require('select2/dropdown/closeOnSelect');
    let AttachBody = $.fn.select2.amd.require('select2/dropdown/attachBody');

    let dropdownAdapter = Utils.Decorate(Utils.Decorate(Utils.Decorate(Dropdown, DropdownSearch), CloseOnSelect), AttachBody);

    $(".js-select2").select2({
      dropdownAdapter: dropdownAdapter,
      minimumResultsForSearch: 0,
      language: {
        noResults: function() {
          return "--";
        }
      }
    });

    countrySelect.on("select2:select", function() {
      loadCities(this.value, url, 0);
    });

    loadCities(countrySelect.val(), url, countrySelect.data("city"));
  }
}

$(document).on('turbolinks:before-cache', function() {
  $("#countrySelect").select2("destroy");
  $("#citySelect").select2("destroy");
});
