require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

// JS
import $ from "jquery";
import "../scripts/polyfills";
import "../scripts/select2/select2";
import "../scripts/select2/select2.min.css";
import "../scripts/web/global";
import "../scripts/web/home";
import "../scripts/web/teachers";
import "../scripts/web/categories";
import "../scripts/web/users";
import "../scripts/web/purchases";

// Stylesheets
import "../styles/web/application.scss";
import "../styles/jaime.scss";
import "../styles/web/devise.scss";
import "../styles/web/courses.scss";
import "../styles/web/events.scss";
// import "../styles/web/lesson_items.scss";
import "../styles/web/course_items.scss";
import "../styles/web/landings.scss";
import "../styles/web/static.scss";
import "../styles/web/cookie_consent.scss";

// Components
import "../components/menu/menu";
// import "../components/cookies_modal/cookies_modal";
import "../components/rating_reviews/rating_reviews";
import "../components/image_input/image_input";
import "../components/user_header/user_header";
import "../components/chat/chat";
import "../components/course_card/course_card";
import "../components/event_card/event_card";
import "../components/teacher_card/teacher_card";
import "../components/category_card/category_card";
import "../components/course_item_card/course_item_card";
import "../components/course_header/course_header";
import "../components/filters/filters";
import "../components/countdown_timer/countdown_timer";
import "../components/langs_select/langs_select";
import "../components/custom_modal/custom_modal";
import "../components/gallery/gallery";

window.throttle = function(func, wait, options) {
  let context, args, result;
  let timeout = null;
  let previous = 0;

  if (!options) {
    options = {};
  }

  let later = function() {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);

    if (!timeout) {
      context = args = null; }
  };

  return function() {
    let now = Date.now();
    if (!previous && options.leading === false) previous = now;
    let remaining = wait - (now - previous);
    context = this;
    args = arguments;

    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      previous = now;
      result = func.apply(context, args);

      if (!timeout) {
        context = args = null; }

    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining); }

    return result;
  };
};

window.debounce = function(func, wait = 100) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

function loadSelect2() {
  let Utils = $.fn.select2.amd.require('select2/utils');
  let Dropdown = $.fn.select2.amd.require('select2/dropdown');
  let DropdownSearch = $.fn.select2.amd.require('select2/dropdown/search');
  let CloseOnSelect = $.fn.select2.amd.require('select2/dropdown/closeOnSelect');
  let AttachBody = $.fn.select2.amd.require('select2/dropdown/attachBody');

  let dropdownAdapter = Utils.Decorate(Utils.Decorate(Utils.Decorate(Dropdown, DropdownSearch), CloseOnSelect), AttachBody);

  $(".js-select2-multiple").select2({
    dropdownAdapter: dropdownAdapter,
    minimumResultsForSearch: 0,
    multiple: true,
    language: {
      noResults: function() {
        return "No se han encontrado resultados";
      }
    }
  })
}

document.addEventListener("turbolinks:load", function() {
  loadSelect2();
});
